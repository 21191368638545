import { template as template_da9cb8711df14333a860039fedb19948 } from "@ember/template-compiler";
const SidebarSectionMessage = template_da9cb8711df14333a860039fedb19948(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
