import { template as template_7f74ae418f5a494d995996ed4ed2e229 } from "@ember/template-compiler";
const FKLabel = template_7f74ae418f5a494d995996ed4ed2e229(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
