import { template as template_384a729048d44aeeabc40e177f2cd893 } from "@ember/template-compiler";
const FKControlMenuContainer = template_384a729048d44aeeabc40e177f2cd893(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
